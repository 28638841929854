import { Box, Container, Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface PropType {
  component: React.FC;
}

const salientFeatures = [
  'Real-Time Engagement Tracking',
  'Enhanced Team Collaboration',
  'Instant, Actionable Insights',
  'Data-Driven Decision Making',
  'Seamless Integration with Slack',
  'Data Anonymization',
];

const SplitPage: React.FC<PropType> = ({ component: Component }) => {
  return (
    <Box
      sx={{
        backgroundColor: '#ececec',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Grid container minHeight='100vh'>
        <Grid
          item
          container
          alignItems='center'
          md={7}
          bgcolor='white'
          sx={{ xs: { display: 'none' }, md: { display: 'flex' } }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '560px',
              margin: '16px auto',
            }}
          >
            <img
              src={require('../assets/blue-logo.png')}
              width={'250px'}
              alt={'Logo'}
              style={{ marginBottom: '24px', alignSelf: 'center' }}
            />

            <Typography variant='h5' fontWeight={700} mb={2} fontSize={30}>
              Try our Standard plan for 40 days, free!
            </Typography>
            <Typography mb={2} fontSize={17}>
              Find out why customers see increased employee engagement,
              collaboration, and productivity using the Standard plan with a
              risk-free 40-day trial. Cancel at anytime.
            </Typography>
            <Grid display='grid' gridTemplateColumns='repeat(2, 1fr)' mb={4}>
              {salientFeatures.map((feature, key) => (
                <Box key={key} display='flex' alignItems='center'>
                  <CheckCircleIcon
                    color='primary'
                    sx={{ fontSize: 20, marginRight: 1 }}
                  />
                  <Typography variant='caption' fontSize={14}>
                    {feature}
                  </Typography>
                </Box>
              ))}
            </Grid>
            <Grid
              container
              component={Paper}
              elevation={6}
              height={400}
              width={560}
            >
              <img
                src={require('../assets/dashboard.png')}
                height='400px'
                width='560px'
                alt='Dashboard screen'
              />
            </Grid>
          </Box>
        </Grid>
        <Grid item container alignItems='center' xs={12} md={5}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '400px',
              margin: '0 auto',
            }}
          >
            <Component />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SplitPage;
